@import "../../theme/variables";

.swiper-template {
    position: relative;

    &.swiper-container {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
        display: block;

        @media screen and (min-width: $breakpoint-tablet) {
            overflow: inherit;
        }
    }

    .swiper {
        width: 100%;
        height: 100%;


        &-wrapper {
            @media screen and (min-width: $breakpoint-tablet) {
                display: grid;
                justify-content: space-between;
                gap: 16px;
                grid-template-columns: repeat(auto-fit, minmax(0, 32%));
                padding: inherit;
            }
        }

        &-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 254px;

            @media screen and (min-width: $breakpoint-tablet) {
                width: 100%;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }


        &-controls {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 50px;
            box-shadow: 4px 10px 30.5px 0px rgba(143, 141, 141, 0.20);
            width: 300px;
            height: 80px;
            margin: -25px auto 0 auto;
            z-index: 10;

            .swiper-button-prev,
            .swiper-button-next,
            .swiper-pagination {
                position: static;
            }

            .swiper-button-next,
            .swiper-button-prev {
                transition: all .2s ease-out;
                overflow: hidden;
                position: relative;
                border: 1px solid #15011d;
                background: #fff;
                border-radius: 50%;
                color: #15011d;
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0;
                margin: 0;

                &:hover,
                &:focus-within {
                    color: #15011d;
                    border-color: #01d1d0;
                    z-index: 1;

                    &:before {
                        transform: translate(0, 0)
                    }
                }

                &:before {
                    content: "";
                    display: block;
                    background: #01d1d0;
                    border-radius: 50%;
                    height: calc(100% + 2px);
                    width: calc(100% + 2px);
                    position: absolute;
                    left: -1px;
                    top: -1px;
                    transform: translate(-100%, 0) rotate(10deg);
                    transform-origin: top left;
                    transition: transform .2s ease-out;
                    will-change: transform;
                    z-index: -1
                }

                &::after {
                    content: "";
                    margin-top: 0;
                    width: 14px;
                    height: 24px;
                    display: block;
                    background: url("./assets/images/icon-chevron-r-dark.svg") no-repeat center
                }
            }


            .swiper-button-prev {
                order: 1;
                position: absolute;
                left: 0;
                top: 0;

                &::after {
                    transform: rotate(180deg);
                }
            }

            .swiper-button-next {
                order: 3;
                position: absolute;
                right: 0;
                top: 0;
            }

            .swiper-pagination {
                order: 2;
                width: max-content;

                &-bullet {
                    width: 15px;
                    height: 15px;
                    opacity: .2;
                    border: 2px solid #000;
                    background-color: #fff;

                    &-active {
                        background-color: #000;
                        opacity: 1;
                    }
                }
            }
        }

    }

    &.swiper-cards,
    &.swiper-card-testimony {
        margin-top: 10px;
        margin-bottom: 70px;

        @media screen and (min-width: $breakpoint-tablet) {
            max-width: 1128px;
            margin: 40px auto 80px auto;
            width: 100%;

            .swiper-controls {
                display: none;
            }
        }

        .swiper-slide {
            box-shadow: 12px 16px 35.5px 0px rgba(143, 141, 141, 0.2);
            height: auto;
            background-color: #fff;

            @media screen and (min-width: $breakpoint-tablet) {
                height: 328px;
                display: flex;
                align-items: flex-start;
            }
        }
    }

    &.swiper-card-testimony {
        .swiper-slide {
            height: auto;

            @media screen and (min-width: $breakpoint-tablet) {
                height: auto;
            }
        }
    }
}