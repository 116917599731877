@import "../../../../shared/theme/variables.scss";

// Styles
.subsection-block {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    padding: 0 40px;
  }

  &__text {
    background: var(--primary-color-blue-gradient-90, linear-gradient(88deg, rgba(0, 112, 173, 0.90) 0.34%, rgba(18, 171, 219, 0.90) 99.46%));
    color: white;
    padding: 24px 16px;
    font-size: 18px;
    line-height: 1.5;
    width: stretch;
    height: fit-content;

    &__title {
      font-family: Ubuntu, Helvetica, Arial, sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 140%; /* 33.6px */
      margin-bottom: 24px;

      em {
        font-size: 24px;
        font-style: italic;
        font-weight: 400;
        line-height: 100%;
      }

    }

    &__link {
      color: white;
      text-decoration: underline;
    }
  }

  &__media {
    position: relative;
    flex: 1;
    width: 100%;

    &__video, &__image {
      width: 100%;
    }

    &__button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      svg {
        width: 48px;
        height: 48px;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &__text {
      max-width: 474px;
      width: 100%;
      margin-bottom: 0;
      z-index: 1;
      display: inline-flex;
      padding: 62px 48px;
      flex-direction: column;
      justify-content: center;
      text-align: left;

      &__title {
        font-size: 36px;
        font-weight: 700;
        line-height: 120%; /* 50.4px */
        margin-bottom: 32px; 

        em {
          font-family: Ubuntu, Helvetica, Arial, sans-serif;
          font-size: 36px;
          font-style: italic;
          font-weight: 400;
          line-height: 120%;
        }

      }

    }

    &__media {
      position: relative;
      margin-left: -48px;
      height: 516px;

      &__video, &__image  {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      &__button {
        svg {
          width: 140px;
          height: 140px;
        }
      }
    }
  }
}

