@import "../../../../shared/theme/variables.scss";

.card {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    text-align: left;
    align-items: center;

    .card__title {
        font-size: $font-size-title;
        font-weight: bold;
        margin-bottom: 16px;
    }

    .card__content {
        align-self: stretch;
        p {
            margin: 0;
            font-size: $font-size-body-xl;
            font-weight: 400;
            line-height: 140%;

            &:last-child {
                margin-top: 16px;
                font-weight: bold;
            }
        }
    }
}
