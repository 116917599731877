@import "../../../../shared/theme/variables.scss";

.subsection-secondary {
    width: 100%;

    @media screen and (min-width: $breakpoint-tablet) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    &__text {
        display: flex;
        padding: 24px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        gap: 8px;
        background: linear-gradient(52deg, #272936 -34.71%, #0070AD 104.91%);

        @media screen and (min-width: $breakpoint-tablet) {
            padding: 87px 128px 87px 40px;
            box-sizing: border-box;
            height: 535px;
            width: 40%;
            order: 2;
        }

        h3 {
            color: var(--Primary-White, #FFF);
            font-family: Ubuntu, Helvetica, Arial, sans-serif;
            font-size: 24px;
            @media screen and (min-width: $breakpoint-tablet) {
                font-size: 36px;
            }
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
            margin-bottom: 24px;
        }
    
        p {
            color: var(--Primary-White, #FFF);
            font-family: Ubuntu, Helvetica, Arial, sans-serif;
            font-size: 16px;
            @media screen and (min-width: $breakpoint-tablet) {
                font-size: 20px;
            }
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin: 0 0 32px 0;
        }
    }

    &__media {
        position: relative;

        @media screen and (min-width: $breakpoint-tablet) {
            height: 535px;
            width: 100%;
            width: -webkit-fill-available;
            order: 1;
        }
        height: 185px;

        img {
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            width: 100%;
        }
    }


}