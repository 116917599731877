@import "./variables";

[class*="MuiFormControl-root"] {
    width: 100% !important;
}

[class*="MuiCheckbox-colorSecondary"][class*="Mui-checked"],
[class*="MuiRadio-colorSecondary"][class*="Mui-checked"] {
    color: $color-primary !important;
}

// input font color and size
[class*="MuiFormLabel-root"],
[class*="MuiInputBase-root"] {
    font-family: Ubuntu, Helvetica, Arial, sans-serif !important;
    font-size: $font-size-body-l !important;
    color: $grey-dark !important;
}

// border color default and on hover
[class*="MuiInput-underline"]::before,
[class*="MuiInput-underline"]:hover:not(.Mui-disabled)::before {
    border-color: $grey-dark !important;
}

// border color on focus
[class*="MuiInput-underline"]::after,
[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-focused"] {
    border-color: $color-primary !important;
}

// label color when focused or shrunk
[class*="MuiFormLabel-root"][class*="Mui-focused"],
[class*="MuiInputLabel-shrink"] {
    color: $grey-medium !important;
}

// helper text
[class*="MuiFormHelperText-root"] {
    font-family: Ubuntu, Helvetica, Arial, sans-serif !important;
    font-size: $font-size-body-l !important;
    color: $grey-medium !important;
}

// textarea
[class*="MuiFormControl-marginNormal"] {
    margin: 15px 0 0 !important;
}
[class*="MuiOutlinedInput-multiline"] {
    border-radius: 12px !important;
}
