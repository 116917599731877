@import "src/app/shared/theme/variables";

.Toastify__toast-container {
    width: 100%;
}

.Toastify__toast--success {
    background: white;
    color: $blue;
    border: 2px solid $cyan;
    border-radius: 5px;
}

.Toastify__toast--error {
    background: $red;
    color: white;
    border-radius: 5px;
}

@media screen and (min-width: $breakpoint-tablet) {
    .Toastify__toast-container {
        width: 400px;
    }
}

@media screen and (min-width: $breakpoint-large) {
    .Toastify__toast-container {
        width: 600px;
    }
}
