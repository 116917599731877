.admin {
    flex: 1 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__dashboard {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        &__item {
            margin: 20px 0;
        }
    }
}
