@import "../../../../shared/theme/variables";
@import "../../../../shared/theme/material";

.join {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $grey-dark;

    .join__wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 80px;
        padding: 20px 15px 50px;
        background: white;
        border-radius: 8px;
        z-index: 2;
        position: relative;
        max-width: 400px;
        box-sizing: border-box;
    }
}

.join__closed {
    margin: 80px 0;
    text-align: center;
}

.join__center {
    align-self: center;
    display: inline-flex;
    flex-flow: column nowrap;
    align-content: center;
    align-items: baseline;
    margin: 0 auto;
}

.join__header {
    width: 100%;
}

.join__title {
    flex-grow: 0;
    margin-bottom: 40px;
    .join__title__text {
        font-size: $font-size-title-l;
        margin: 0;
        text-align: center;

        strong {
            font-weight: normal;
            color: $light-blue;
        }
    }
}

.join__form {
    display: grid;
    grid-template-areas:
        "."
        "civility"
        "first-name"
        "last-name"
        "mobile"
        "email"
        "."
        "graduating-year"
        "school-name"
        "school-other"
        "major"
        "cv"
        "."
        "how"
        "how-other"
        "why"
        "extracurricular"
        "actions"
        "communications"
        "conditions"
        "tos"
        "submit"
        "mandatory-label"
        "disclaimer";
    grid-template-columns: 1fr;
    grid-template-rows: repeat(22, max-content);
    row-gap: 40px;

    .join__form__field--error {
        color: $red;
    }

    .join__form__field--civility {
        grid-area: civility;
        & > .MuiFormControl-root > legend {
            display: none;
        }
    }

    .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
        font-size: $font-size-body-l !important;
    }

    .join__form__field--first-name {
        grid-area: first-name;
    }

    .join__form__field--last-name {
        grid-area: last-name;
    }

    .join__form__field--mobile {
        grid-area: mobile;
        // margin-top: 17px;
    }

    .join__form__field--email {
        grid-area: email;
    }

    .join__form__field--graduating-year {
        grid-area: graduating-year;
    }

    .join__form__field--major {
        grid-area: major;
    }

    .join__form__field--school-name {
        grid-area: school-name;
    }

    .join__form__field--school-other {
        grid-area: school-other;
    }

    .join__form__field--cv {
        grid-area: cv;
        label {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
        .cv__info {
            margin-left: 10px;
        }
        .button-primary {
            display: flex;
            flex-direction: column;
        }
    }

    .join__form__field--how {
        grid-area: how;
    }

    .join__form__field--how-other {
        grid-area: how-other;
    }

    .join__form__field--why {
        grid-area: why;
    }

    .join__form__field--extracurricular {
        grid-area: extracurricular;
    }

    .join__form__field--actions {
        grid-area: actions;
    }
    .join__form__field--communications {
        grid-area: communications;
    }

    .join__form__field--conditions {
        grid-area: conditions;
    }

    .join__form__field--tos {
        grid-area: tos;
        margin-top: -35px;
    }

    .join__form__field--disclaimer {
        grid-area: disclaimer;
        font-size: $font-size-body-s;
        color: $blue;

        a {
            text-decoration: underline;
            color: $cyan;
        }
    }

    .join__form__field--submit {
        grid-area: submit;
        justify-self: stretch;
        width: 100%;
        & > button {
            width: 100%;
        }
    }

    .join__label-mandatory {
        grid-area: mandatory-label;
        margin-top: -30px;
        justify-self: center;
        & > strong {
            color: $color-primary;
        }
    }

    .join__form__section-title {
        font-size: 2.4rem;
        &:not(:first-child) {
            margin-top: 15px;
        }
    }

    .join__form__radio-group {
        display: flex;
        flex-direction: row;
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .join {
        padding: 40px 135px;

        .join__wrapper {
            margin-top: 0;
            padding: 40px 100px;
            box-shadow: $box-shadow-base;
            max-width: 1280px;
        }
    }

    .join__header {
        position: absolute;
        width: 100%;
    }

    .join__form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(18, max-content);
        grid-template-areas:
            ".                  ."
            "civility           ."
            "first-name          last-name"
            "mobile             email"
            ".                  ."
            "graduating-year    major"
            "school-name        major"
            "school-other       ."
            "cv                 cv"
            ".                  ."
            "how                how-other"
            "why                why"
            "extracurricular    extracurricular"
            "actions            actions"
            "communications     communications"
            "conditions         conditions"
            "tos                tos"
            "submit             submit"
            "mandatory-label    mandatory-label"
            "disclaimer         disclaimer";
        gap: 30px;

        .join__form__section-title {
            grid-column: span 2;
            font-size: $font-size-title-s;
            &:not(:first-child) {
                margin-top: 30px;
            }
        }

        .join__form__field--conditions,
        .join__form__field--tos {
            margin-left: 100px;
        }

        .join__form__field--cv {
            .button-primary {
                display: inline-flex;
                flex-direction: row;
            }
        }
        .join__form__field--tos {
            margin-top: -35px;
        }

        .join__form__field--submit {
            align-items: center;
            & > button {
                width: auto;
                padding-left: 100px;
                padding-right: 100px;
            }
        }

        .join__label-mandatory {
            grid-column: span 2;
            margin-top: -20px;
            font-size: $font-size-body-m;
            & > strong {
                color: $color-primary;
            }
        }
    }
}
