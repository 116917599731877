@import "../../theme/variables";

.footer {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: #F6F6F6;

    @media screen and (min-width: $breakpoint-tablet) {
        flex-direction: row;
        padding: 32px 48px 32px 24px;
        justify-content: space-between;
        align-items: center;
    }

    p {
        margin: 0;
    }

    &__brand {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;
        @media screen and (min-width: $breakpoint-tablet) {
            width: 350px;
            margin-bottom: inherit;
        }
        .logo {
            img {
                width: 156px;
                @media screen and (min-width: $breakpoint-tablet) {
                    width: 231px ;
                    padding: 24.192px 41.013px 19.09px 41.473px;
                } 
            }

            margin-bottom: 16px; 

        }
        .copyright {
            color: rgba(39, 41, 54, 0.85);
            font-family: Ubuntu, Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; 
        }
    }

    &__legal {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @media screen and (min-width: $breakpoint-tablet) {
            flex-direction: column;
            align-items: self-start;
        }
        
        a {
            color: $grey-dark;
            font-family: Ubuntu, Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            margin-bottom: 16px;
            
            &:hover {
                text-decoration: underline;
            }

            &:last-child {
                margin-bottom: 32px;
                @media screen and (min-width: $breakpoint-tablet) {
                    margin-bottom: inherit;
                }
            }
        }
    }

    &__contact {
        width: 100%;
        border-top: 0.5px solid #93949A;
        padding-top: 12px;
        @media screen and (min-width: $breakpoint-tablet) {
            width: inherit;
            border-top: inherit;
            padding-top: inherit;
        }
        .email {
            margin-bottom: 12px;
            display: flex;
            flex-direction: column;
            .title {
                color: $grey-dark;
                font-family: Ubuntu, Helvetica, Arial, sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 140%;
            }
            a {
                color: $grey-dark;
                font-family: Ubuntu, Helvetica, Arial, sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 140%;
                text-decoration: underline;
            }
        }        
    }
}
