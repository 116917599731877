@import "../../theme/variables";

.header {
    display: grid;
    grid-template-columns: 120px 1fr 50px;
    grid-template-rows: 40px;
    grid-template-areas: "logo . toggle";
    background-color: $white;
    color: $blue;
    padding: 10px var(--padding-outer-sm);
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 12px 16px 35.5px 0px rgba(143, 141, 141, 0.20);
    
}

.header__logo {
    grid-area: logo;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__logo__image {
    max-width: 100%;
    width: 150px;

    @media screen and (min-width: $breakpoint-tablet) {
        width: 180px;
    }
}

.header__toggle {
    grid-area: toggle;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__toggle__button {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 0;
    background: none;
    border: 2px solid transparent;
    border-radius: 100%;
    transition: all $easing-accelerate 0.1s;
    cursor: pointer;
}
.header__toggle__button__part {
    width: 30px;
    height: 4px;
    margin: 2px;
    background: currentColor;
    border-radius: 3px;
    pointer-events: none;
    background: black;

    &.open {
        &:nth-child(1) {
            animation: toggle-top-open 0.3s $easing-accelerate forwards;
        }
        &:nth-child(2) {
            opacity: 0;
            transition: all $easing-accelerate 0.1s;
        }
        &:nth-child(3) {
            animation: toggle-bottom-open 0.3s $easing-accelerate forwards;
        }
    }

    &.closed {
        &:nth-child(1) {
            animation: toggle-top-close 0.3s $easing-decelerate forwards;
        }
        &:nth-child(2) {
            opacity: 1;
            transition: all $easing-decelerate 0.1s 0.2s background-color $easing-accelerate 0.1s;
        }
        &:nth-child(3) {
            animation: toggle-bottom-close 0.3s $easing-decelerate forwards;
        }
    }
}

.header__nav {
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    top: 60px;
    bottom: 0;
    left: -100vw;
    z-index: 1000;
    width: 80vw;
    padding: 16px;
    background: $white;
    box-shadow: 4px 6px 14px -2px rgba(#000, 0.3);
    box-sizing: border-box;
    transition: transform ease-in 0.2s;
}

.header__nav--open {
    transform: translateX(100vw);
}

.header__nav__link {
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    font-size: $font-size-body-xl;
    line-height: 1.6;
    color: $grey-dark;
}

.header__nav__separator {
    display: none;
}

.header__nav__link--active {
    font-weight: bold;

    &::before {
        background: $light-blue;
    }
}

.header__nav__button {
    font-size: $font-size-body-l;
    font-weight: 500;
    line-height: 1.6;
    margin: 24px 0;
    background-color: $color-primary;
    color: white;
    border-radius: 12px;
    padding: 10px 0;
    text-align: center;
}

@media screen and (min-width: $breakpoint-tablet) {
    .header {
        padding: 11px 40px 12px 40px;
        display: grid;
        grid-template-columns: 210px 1fr;
        grid-template-rows: 50px;
        grid-template-areas: "logo nav";
        position: relative;
    }

    .header__toggle {
        display: none;
    }

    .header__nav {
        grid-area: nav;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        position: unset;
        top: unset;
        bottom: unset;
        left: unset;
        z-index: unset;
        width: unset;
        padding: 0;
        background: transparent;
        box-shadow: none;
        box-sizing: border-box;
        transition: none;
    }

    .header__nav__link {
        margin: 0 0 0 30px;
        &::before {
            display: inline-block;
            position: unset;
        }
    }

    .header__nav__button {
        margin: 0;
        padding: 10px 35px;
        text-align: center;
    }

    .header__nav__separator {
        display: block;
        width: 2px;
        height: 100%;
        background-color: $grey-light;
        margin: 0 40px;
    }
}

@keyframes toggle-top-open {
    0% {
        transform: translate(0) rotate(0);
    }
    25% {
        transform: translateY(8px) rotate(0);
    }
    100% {
        transform: translateY(8px) rotate(45deg);
    }
}

@keyframes toggle-bottom-open {
    0% {
        transform: translate(0) rotate(0);
    }
    25% {
        transform: translateY(-8px) rotate(0);
    }
    100% {
        transform: translateY(-8px) rotate(-45deg);
    }
}

@keyframes toggle-top-close {
    0% {
        transform: translateY(8px) rotate(45deg);
    }
    75% {
        transform: translateY(8px) rotate(0);
    }
    100% {
        transform: translate(0) rotate(0);
    }
}

@keyframes toggle-bottom-close {
    0% {
        transform: translateY(-8px) rotate(-45deg);
    }
    75% {
        transform: translateY(-8px) rotate(0);
    }
    100% {
        transform: translate(0) rotate(0);
    }
}