@import "../../../../../shared/theme/variables.scss";

.home-banner {
    position: relative;
    max-width: 100vw;

    &__content {
        position: relative;
        overflow: hidden;
        @media screen and (min-width: $breakpoint-tablet) {
            height: calc(100vh - 73px);
        }

        video, .img {
            width: 100%;
            @media screen and (min-width: $breakpoint-tablet) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
            }

        }
    }

    &__logo-title-container {
        display: flex;
        padding: 24px 16px;
        align-items: center;
        gap: 24px;
        background: var(--primary-color-dark-grey-gradient-80, linear-gradient(228deg, rgba(0, 112, 173, 0.80) 2.57%, rgba(39, 41, 54, 0.80) 104.06%));
        color: #fff;

        @media screen and (min-width: $breakpoint-tablet) {
            position: absolute;
            display: inline-flex;
            max-width: 1072px;
            width: 100%;
            bottom: 64px;
            padding: 32px 24px 32px 40px;
            gap: 32px;
        }
    }

    &__logo {
        max-width: 88px;
        @media screen and (min-width: 768px) {
            max-width: 226px;
        }
        height: auto;
    }

    &__title {
        font-family: Ubuntu, Helvetica, Arial, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        @media screen and (min-width: $breakpoint-tablet) {
            font-size: 48px;
        }

        span {
            font-style: italic;
            font-weight: 400;
        }
    }

    &__shade {
        display: none;
        @media screen and (min-width: $breakpoint-tablet) {
            display: block;
            position: absolute;
            bottom: 0;
            right: 90px;
        }

    }
}
