@import "../../../../shared/theme/variables";

.join-success {
    display: flex;
    flex-direction: column;
    flex: 1;

    .join-success__wrapper {
        padding: 40px 16px 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex: 1;
    }

    .join-success__title {
        align-self: center;
        font-size: $font-size-title-l;
    }

    .join-success__image {
        align-self: center;
        width: 72px;
        height: 72px;
    }

    .join-success__text {
        margin: 0;
        text-align: center;
        font-size: $font-size-body-xl;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .join-success__text--end {
        text-align: center;
        font-weight: bold;
    }

    .join-success__title {
        margin: 30px 0 20px 0;
    }

    .join-success__message {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .join-success {
        background: $white;

        .join-success__image {
            width: 96px;
            height: 96px;
        }

        .join-success__message {
            max-width: 400px;
        }
    }
}
