@import "../../../theme/variables";

.footer {
    &__contact {
        .social-links {
            display: flex;
            justify-content: start;
            align-items: flex-start;
            align-self: stretch;
            list-style: none;
            padding: 0;

            @media screen and (min-width: $breakpoint-tablet) {
                margin-right: 16px;
                justify-content: flex-start;
            }


            li {
                    margin-right: 16px;
                    
                    &:last-child {
                        margin-right: 0;
                    }
            }

            a {
                svg {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
}
