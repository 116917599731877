@import "../../../../../shared/theme/variables.scss";

.card-testimony {
    background-color: $white;
    flex-grow: 1;
    height: 100%;
    text-align: left;

    p {
        margin: 0;
    }

    .card-testimony__content-wrapper {
        padding: 24px 24px 0 24px;
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-columns: 56px 1fr;
        grid-template-rows: auto min-content auto;
        grid-template-areas:
            "profil name"
            "profil job"
            "icon   text";
    }

    .card-testimony__profile {
        grid-area: profil;
        border-radius: 100%;
        width: 56px;
    }

    .card-testimony__name {
        grid-area: name;
        color: $grey-dark;
        font-weight: 500;
        font-size: $font-size-title-s;
        line-height: 1.56;
        margin-left: 8px;
        align-self: end;
    }

    .card-testimony__job {
        grid-area: job;
        color: $grey-medium;
        font-size: $font-size-body-l;
        line-height: 1.15;
        margin-left: 8px;
        align-self: end;
    }

    .card-testimony__icon,
    .icon {
        grid-area: icon;
        font-size: 32px;
        align-self: start;
        justify-self: center;
        margin-top: 32px;
        color: $light-blue;
    }

    .card-testimony__text {
        margin-top: 16px;
        padding: 0 24px 24px 24px;
        grid-area: text;
        font-size: $font-size-body-xl;
        line-height: 1.6;
    }
}
