$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-check: "\f00c";
$icon-download: "\f019";
$icon-calendar: "\f073";
$icon-graduation-cap: "\f19d";
$icon-mortar-board: "\f19d";
$icon-file-pdf-o: "\f1c1";
$icon-trash: "\f1f8";
$icon-at: "\f1fa";
$icon-chevron-right: "\e900";
$icon-chevron-left: "\e901";
$icon-profile: "\e923";
$icon-phone: "\e942";

@font-face {
    font-family: "icomoon";
    src: url("../assets/icons/icomoon.ttf?o1fb0a") format("truetype"), url("../assets/icons/icomoon.woff?o1fb0a") format("woff"),
        url("../assets/icons/icomoon.svg?o1fb0a#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: "icomoon", sans-serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-download {
    &:before {
        content: $icon-download;
    }
}
.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}
.icon-graduation-cap {
    &:before {
        content: $icon-graduation-cap;
    }
}
.icon-mortar-board {
    &:before {
        content: $icon-mortar-board;
    }
}
.icon-file-pdf-o {
    &:before {
        content: $icon-file-pdf-o;
    }
}
.icon-trash {
    &:before {
        content: $icon-trash;
    }
}
.icon-at {
    &:before {
        content: $icon-at;
    }
}
.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}
.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}
.icon-profile {
    &:before {
        content: $icon-profile;
    }
}
.icon-phone {
    &:before {
        content: $icon-phone;
    }
}
