@import "../../../../shared/theme/variables";

.list-candidates {
    padding: 100px var(--padding-outer-sm) 30px var(--padding-outer-sm);

    &__table {
        display: grid;
        grid-template-columns: auto repeat(4, max-content) 1fr max-content 60px repeat(3, 50px);
        grid-template-rows: auto;

        &__item {
            padding: 10px;
            font-size: $font-size-body-m;
            border-bottom: 1px solid $grey-medium;

            &--head {
                border-bottom: 2px solid $blue;
            }

            &--icon {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: flex-start;

                .icon-check {
                    color: $green;
                }

                .icon-trash {
                    color: $red;
                }
            }
        }
    }
}
