// Breakpoints
$breakpoint-mobile: 576px;
$breakpoint-tablet: 1200px;
$breakpoint-desktop: 1400px;
$breakpoint-large: 992px;

// Color palette
$blue-color: #007bff;
$gray-color: #6c757d;
$blue: #2a0d3c;
// $blue-bg: #28193c;
$blue-bg: black;
$red: #ef3850;
$green: #76ef63;
$cyan: #10abda;
$turquoise: #00e6e3;
// $grey-light: #f8f8f8;
// $grey-medium: #ececec;
$grey-shadow: #bfbfbf;
// $grey-dark: #7f7f7f;
$white: #ffffff;

$new-blue: #0070ad;
$light-blue: #12b2db;
$grey-dark: #272936;
$grey-medium: #938d8e;
$grey-light: #f1eeef;
$grey-very-light: #f7f7f7;
$purple: #300540;
$red: #ff304c;

$color-primary: $new-blue;
$color-secondary: $red;

$color-text-base: $blue;
$color-text-light: $white;

// Animations easing
$easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
$easing-accelerate: cubic-bezier(0.4, 0, 1, 1);
$easing-decelerate: cubic-bezier(0, 0, 0.2, 1);
$easing-bounce: cubic-bezier(0.8, 0.1, 0.2, 1.6);

$font-size-title-l: 3.2rem; //changed
$font-size-title: 2.4rem; //changed
$font-size-title-m: 2.125rem;
$font-size-title-s: 1.8rem; //changed
$font-size-body-xl: 1.6rem; //changed
$font-size-body-l: 1.4rem; //changed
$font-size-body: 1.25rem;
$font-size-body-m: 1rem;
$font-size-body-s: 0.875rem;

$box-shadow-base: 0 7px 64px 0 rgba(0, 0, 0, 0.07);

$background-band-deco: $white no-repeat center bottom / 100% 8px
    linear-gradient(to right, #0070ad 70%, #55a0c8 70%, #55a0c8 90%, #aacfe4 90%);

:root {
    --font-size-root: 10px;

    // Padding
    --padding-outer: 30px;
    --padding-outer-sm: 20px;
}

@media screen and (min-width: $breakpoint-tablet) {
    $font-size-title-l: 4rem;

    :root {
        --font-size-root: 10px;

        // Padding
        --padding-outer: 60px;
        --padding-outer-sm: 30px;

        //card
        --card-border-bottom-color: #{$grey-light};
    }
}
