@font-face {
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    src: url("../assets/fonts/Ubuntu-Regular.ttf") format("truetype"),
         url("../assets/fonts/Ubuntu-Regular.woff") format("woff");
}

@font-face {
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    src: url("../assets/fonts/Ubuntu-Bold.ttf") format("truetype"),
         url("../assets/fonts/Ubuntu-Bold.woff") format("woff");
}
