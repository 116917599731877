@import "./variables.scss";

html {
    font-size: var(--font-size-root);
}

body {
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    font-size: $font-size-body;
    color: $grey-dark;
}

h2,
h3 {
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    font-weight: 500;
    // font-size: $font-size-title-m;
}

// h3 {
//   font-family: Ubuntu-Bold, Helvetica, Arial, sans-serif;
//   font-size: $font-size-title-s;
// }
