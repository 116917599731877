@import "../../theme/variables";

.button-primary {
    --front-color: #{$color-text-light};
    --btn-color: #{$color-primary};

    // Couleurs des différents éléments du bouton
    --btn-text-color: var(--front-color);
    --btn-bg-color: var(--btn-color);
    --btn-border-color: var(--btn-color);

    box-sizing: border-box;
    min-height: 48px;
    background-color: var(--btn-bg-color);
    color: var(--btn-text-color);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-size: $font-size-body;
    text-decoration: none;
    text-transform: none;
    border: 1px solid var(--btn-border-color);
    border-radius: 12px;
    appearance: none;
    cursor: pointer;
    white-space: nowrap;
    font-family: Ubuntu, Helvetica, Arial, sans-serif;

    &:focus,
    &:hover {
        --btn-color: #{$color-secondary};
    }
}

.button-primary--outlined {
    --btn-text-color: var(--btn-color);
    --btn-bg-color: var(--front-color);
    --btn-border-color: var(--btn-color);
}

.button-primary--tertiary {
    position: relative;
    overflow: hidden;
    padding: 16px 32px;
    background: transparent;
    border-radius: calc(50 / 16* 1rem);
    border: 1px solid $grey-dark;
    color: $grey-dark;
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 16px;
    transition: all .2s ease-out;

    @media screen and (min-width: $breakpoint-tablet) {
        padding: 24px 32px;
        font-size: 32px;
    }

    &:before {
        content: "";
        display: block;
        background: $turquoise;
        border-radius: 48px;
        height: calc(100% + 2px);
        width: calc(100% + 2px);
        position: absolute;
        left: -1px;
        top: -1px;
        transform: translate(-100%,0) rotate(10deg);
        transform-origin: top left;
        transition: transform .5s ease-out;
        z-index: -1
    }

    &:after {
        content: "";
        transition: all .2s ease-out;
    }

    &:hover,
    &:focus,
    &:focus-within {
        color: #15011d;
        border-color: $turquoise;
        background: $turquoise;
        box-shadow: 0 6px 30px 0 rgba(0,0,0,.2);
        transform: scale(1.1);
        z-index: 1;

        &:before {
            transform: translate(0);
        }
    }

}

.btn-quaternary {
    width: fit-content;
    position: relative;
    overflow: hidden;
    padding: 16px 32px;
    background: transparent;
    border-radius: calc(50 / 16* 1rem);
    border: 1px solid $grey-dark;
    color: $grey-dark;
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 16px;
    transition: all .2s ease-out;
    color: #FFF;
    border: 2px solid #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    &:before {
        content: "";
        display: block;
        background: #00e6e3;
        border-radius: 48px;
        height: calc(100% + 2px);
        width: calc(100% + 2px);
        position: absolute;
        left: -1px;
        top: -1px;
        transform: translate(-100%,0) rotate(10deg);
        transform-origin: top left;
        transition: transform .5s ease-out;
        z-index: -1;
    }

    &:after {
        content: "";
        background: url("./assets/svg/icon-more-small-white.svg") no-repeat;
        width: 23px;
        height: 14px;
        margin-left: 16px;
        transition: all .2s ease-out;
        flex-shrink: 0;
        display: block;
    }

    &:hover,
    &:focus,
    &:focus-within {
        color: #15011d;
        border-color: #00e6e3;
        background: #00e6e3;
        box-shadow: 0 6px 30px 0 rgba(0,0,0,.2);
        transform: scale(1.1);
        z-index: 1;

        &:after {
            background: url("./assets/svg/icon-more-small-black.svg") no-repeat;
        }

        &:before {
            transform: translate(0);
        }
    }

}
