@import "../../../../shared/theme/variables";

.group {

    .swiper-cards {
        padding: 16px;
        margin: 40px auto 100px auto;

        @media screen and (min-width: $breakpoint-tablet) {
            padding: inherit;
            margin: 80px auto 128px auto;
        }

        .swiper-wrapper {
            @media screen and (min-width: $breakpoint-tablet) {
                display: grid;
                justify-content: space-between;
                gap: 16px;
                grid-template-columns: repeat(auto-fit, minmax(0, 23%));
                padding: inherit;    
            }
        }


        .swiper-slide {
            height: auto;
            width: 254px;

            @media screen and (min-width: $breakpoint-tablet) {
            }

            @media screen and (min-width: $breakpoint-tablet) {
                width: 100%;
            }
        }
    }

    .subsection-block {
        margin-bottom: 40px;

        @media screen and (min-width: $breakpoint-tablet) {
            margin-bottom: 80px;
        }
    }


    p {
        margin: 0;
    }

    .intro {
        background: #F6F6F6;

        .group__intro-wrapper {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            justify-content: space-between;

            @media screen and (min-width: $breakpoint-tablet) {
                display: flex;
                flex-direction: row;
                margin: auto;
            }

            .group__intro {
                padding: 24px 16px;

                @media screen and (min-width: $breakpoint-tablet) {
                    padding: 50px;
                    width: 45%;
                }

                .get-future {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;

                    @media screen and (min-width: $breakpoint-tablet) {
                        flex-direction: row;
                        align-items: center;
                        justify-content: start;
                    }

                    p,
                    a {
                        display: flex;
                        font-family: Ubuntu, Helvetica, Arial, sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                        color: #0070AD;
                    }

                    a:hover {
                        text-decoration: underline;
                    }

                    .separator {
                        transform: rotate(-90deg);

                        @media screen and (min-width: $breakpoint-tablet) {
                            margin: 0 12px;
                            transform: rotate(0);
                        }
                    }

                }

                .group__title {
                    color: $grey-dark;
                    font-family: Ubuntu, Helvetica, Arial, sans-serif;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    margin-bottom: 16px;
                    text-align: center;

                    @media screen and (min-width: $breakpoint-tablet) {
                        margin-bottom: 24px;
                        text-align: left;
                    }
                }

                .group__intro__content {
                    .group__intro__text {
                        color: $grey-dark;
                        font-family: Ubuntu, Helvetica, Arial, sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                        margin-bottom: 32px;

                        @media screen and (min-width: $breakpoint-tablet) {
                            margin-bottom: 40px;
                        }
                    }
                }

            }

            .group__video {
                position: relative;
                width: auto;
                height: 177.74px;

                @media screen and (min-width: $breakpoint-tablet) {
                    height: 417px;
                    width: 55%;
                }

                video {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;

                    @media screen and (min-width: $breakpoint-tablet) {
                        width: inherit
                    }
                }
            }
        }
    }




    .group__know {
        display: grid;
        grid-template-columns: 1fr;
        margin: 0;
    }

    .group__know__intro {
        width: 100%;
    }

    .group__know__item {
        width: 100%;

        &--hide {
            display: none;
        }
    }

    .group__know__link {
        display: flex;
        align-items: center;
        font-size: $font-size-body-l;
        color: $color-primary;
        text-decoration: underline;
        margin: 15px 0;
        font-family: Ubuntu, Helvetica, Arial, sans-serif;
        font-weight: 500;
        line-height: 1.86;
    }

    .group__know__link__arrow {
        margin-left: 1ch;
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .group {

        .group__title {
            font-size: 64px;
        }

        .group__intro-wrapper {
            display: flex;
            flex-direction: row;
        }

        .group__card-list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr;
            align-items: stretch;
            justify-content: center;
            justify-items: center;

            .card__wrapper {
                min-height: 275px;
            }
        }

        .group__know {
            grid-template-columns: max-content repeat(3, 270px);
            grid-auto-rows: auto auto;
            justify-content: center;
            margin-bottom: 80px;
        }

        .group__know__intro {
            grid-row: span 2;
        }

        .group__know__item {
            max-width: 270px;

            &--hide {
                display: block;
            }

            &--first-row {
                margin-top: 106px;
            }
        }

        .group__know__link {
            display: none;
        }
    }
}