@import "../../../../shared/theme/variables";

.faq {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 40px 16px 0;
    max-width: 400px;
    width: calc(100% - 32px);
    * {
        font-family: Ubuntu, Helvetica, Arial, sans-serif;
    }
}

.faq__title {
    font-size: 18px;
    font-weight: normal;
    margin: 0 auto 40px;

    strong {
        font-weight: bold;
    }
}

.faq-toggle__question {
    background: none;
    border: none;
    display: flex;
    font-size: $font-size-body-s; // 14px
    flex-direction: column;
    text-align: left;
    padding: 24px;
    width: 100%;
    margin-bottom: 16px;
    box-shadow: 12px 16px 35.5px 0px rgba(143, 141, 141, 0.2);
    color: black;

    &:hover {
        background-color: #dce7ed;
    }

    &:focus {
        cursor: pointer;
    }

    &[aria-expanded="true"] {
        .faq-toggle__icon {
            transform: rotate(180deg);
        }
    }
}

.faq-toggle__question__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.faq-toggle__question__title__text {
    font-size: $font-size-title-s;
    font-weight: 600;
    width: 84%;
}

.faq-toggle__answer {
    font-size: 16px;
    line-height: 1.6;
    margin-top: 12px;
    margin-left: 0;
    color: black;
}

.faq-toggle__answer--hidden {
    display: none;
}

@media screen and (min-width: $breakpoint-tablet) {
    .faq {
        display: flex;
        max-width: 100%;
        width: calc(1280px - 32px);
        margin: 0 auto;
        padding: 40px 16px 0;
    }

    .faq-toggle__question__title__text {
        font-size: 24px;
    }
    .faq__title {
        font-size: 48px;
    }
}
