body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-size: inherit;
}

button {
    background: none;
    border: none;
    cursor: pointer;
}

button:focus {
    outline: none;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
}
