@import "../../../../shared/theme/variables";

.home {

    &-inscription {
        background-color: #f7f7f7;
        display: flex;
        justify-content: center;
        align-items: center;

        &__wrapper {
            padding: 24px 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;


            @media screen and (min-width: $breakpoint-tablet) {
                padding: 30px 16px;
                display: flex;
                flex-direction: row;
                text-align: left;
                gap: 149px;
                max-width: 1128px;
                margin: auto;
            }

            @media screen and (min-width: 1200px) {
                padding: 30px 0;
            }

            h2 {
                color: $grey-dark ;
                /* h3 medium */
                font-size: 18px;
                font-weight: 300;
                line-height: 140%;
                text-align: center;
                margin-bottom: 24px;

                @media screen and (min-width: $breakpoint-tablet) {
                    font-size: 24px;
                    margin-bottom: inherit;
                }
            }
        }

    }

    &-swiper-component-secondary {
        position: relative;
        overflow: hidden;
        .shade {
            display: none;
            @media screen and (min-width: $breakpoint-tablet) {
                display: block;
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                transform: rotate(5.829deg);
            }

        }
    }

    .home__testimony {

        @media screen and (min-width: $breakpoint-tablet) {
            max-width: 1128px;
            margin: 0px auto 100px auto;
            width: 100%;
            justify-self: center;
        }

        &__title {
            font-size: $font-size-title;
            line-height: 1.33;
            align-self: center;
            margin-bottom: 24px;
            text-align: left;
            background: linear-gradient(227deg, #12ABDB 1.62%, #0070AD 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding: 0 16px;
            font-weight: 500;
    
            @media screen and (min-width: $breakpoint-tablet) {
                padding: inherit;
                font-size: $font-size-title-l;
                align-self: start;
                line-height: 1.63;
            }
        }

        &__title-link {
            &:hover {
                text-decoration: underline;
                color: #0070AD;
            }
        }
    }
}