@import "../../theme/variables";


.swiper-template {

    &.swiper-secondary {
        padding-top: 30px;
        margin-top: 10px;
        margin-bottom: 50px;
        padding-bottom: 30px;

        @media screen and (min-width: $breakpoint-tablet) {
            margin: 40px auto;
            padding: 40px 0;
            width: 100%;


        }

        .swiper {

            &-wrapper {
                width: 100%;
                display: flex;
                gap: inherit;
            }

            &-slide {
                width: 100%;
                @media screen and (min-width: $breakpoint-tablet) {
                    width: auto;
                }
            }
        } 
    }

}