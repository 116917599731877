@import "../../../../shared/theme/variables";
@import "../../../../shared/theme/material";

.login {
    background: white;
    color: $blue;
    flex: 1 0 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    & + .footer {
        background: transparent;
    }

    &__form {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: $breakpoint-large) {
            width: 600px;
        }

        > * {
            margin: 20px 0 !important;
        }
    }
}
